import React, { FC } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal } from 'react-bootstrap';
import { getId } from '../../shared/utils';

type Props = {
  chapterName: string;
  videoLink?: string;
  text?: string;
  show: boolean;
  handleClose: () => void;
};

const LessonModal: FC<Props> = ({ chapterName, videoLink, text, show, handleClose }) => {
  const videoId = videoLink ? getId(videoLink) : null;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{chapterName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {videoId ? (
          <div className="tw-aspect-video">
            <iframe
              width="100%"
              height="100%"
              src={`//www.youtube-nocookie.com/embed/${videoId}?autoplay=0&showinfo=0&controls=0&rel=0`}
              frameBorder={0}
              title={chapterName as string}
              allowFullScreen
            ></iframe>
          </div>
        ) : text ? (
          <p className="!tw-font-[400] !tw-text-justify !tw-text-[20px] !tw-italic tw-p-2">{text}</p>
        ) : (
          <div className="text-center h5 pt-3">деректер жоқ</div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default LessonModal;
