import React, { createContext, useEffect, useState } from 'react';
import './App.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Outlet, Route, Routes } from 'react-router-dom';
import CategoriesPage from './pages/categoriesPage/categoriesPage';
import ChaptersPage from './pages/chaptersPage/chaptersPage';
import ChaptersDetailsPage from './pages/chapterDetailsPage/chapterDetailsPage';
import { QuizPage } from './pages/quizPage/quizPage';
import { LearningPagesLayout } from './layout/learningPagesLayout';
import { MainPage } from './pages/mainPage/mainPage';
import LevelQuizPage from './pages/levelQuizPage/levelQuizPage';
import { Category, User, UserLevel } from './shared/types';
import { ProtectedRoute } from './config/routes/protectedRoute';

interface IAppContext {
  currentUser: User | null;
  selectedLevel: UserLevel | null;
  changeSelectedLevel?: (level: UserLevel) => void;
}

export const UserContext = createContext<IAppContext>({
  currentUser: null,
  selectedLevel: null,
});

function App() {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [selectedLevel, setSelectedLevel] = useState<UserLevel | null>(null);

  const changeSelectedLevel = (level: UserLevel) => {
    setSelectedLevel(level);
  };

  useEffect(() => {
    (async () => {
      const formData = new FormData();
      formData.append('r_get_user_status', 'true');

      fetch('https://saryarqatil.kz/cab/corecab.php', {
        method: 'POST',
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setCurrentUser(data);
        });
    })();
  }, []);

  return (
    // <Routes>
    //   <Route path={'/'} element={<MainPage />} />
    //   <Route
    //     path={'/learn'}
    //     element={<LearningPagesLayout />}
    //   >
    //     <Route path={'a1'} element={<Outlet />}>
    //       <Route element={<CategoriesPage />} path={'categories'} />
    //       <Route element={<ChaptersPage />} path={'categories/:categoryId/chapters'} />
    //       <Route element={<ChaptersDetailsPage />} path={'categories/:categoryId/chapters/:chapterId'} />
    //       <Route element={<QuizPage />} path={'categories/:categoryId/chapters/:chapterId/quiz'} />
    //     </Route>
    //     <Route path="level-quiz" element={<LevelQuizPage />} />
    //   </Route>
    // </Routes>
    <div className="tw-min-h-[calc(100vh-182.62px)]">
      <UserContext.Provider value={{ currentUser, selectedLevel, changeSelectedLevel }}>
        <Routes>
          <Route path={'/'} element={<MainPage />} />
          <Route element={<ProtectedRoute />}>
            <Route element={<CategoriesPage />} path={'/categories'} />
            <Route element={<ChaptersPage />} path={'/categories/:categoryId/chapters'} />
            <Route element={<ChaptersDetailsPage />} path={'/categories/:categoryId/chapters/:chapterId'} />
            <Route element={<QuizPage />} path={'/categories/:categoryId/chapters/:chapterId/quiz'} />
          </Route>
        </Routes>
      </UserContext.Provider>
    </div>
  );
}

export default App;
