export enum UserLevel {
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  C1 = 'C1',
}
export enum QuestionType {
  One = 'type_1',
  Two = 'type_2',
  Three = 'type_3',
  Four = 'type_4',
  Five = 'type_5',
  Six = 'type_6',
  Seven = 'type_7',
  Eight = 'type_8',
  Nine = 'type_9',
  Ten = 'type_10',
  Eleven = 'type_11',
  Twelve = 'type_12',
  Thirteen = 'type_13',
}
export type AnswerType1 = {
  [key: string]: {
    correct: string;
    value: string;
  };
};
export type AnswerType2 = {
  '1': {
    correct: string;
  };
};
export type AnswerType3 = {
  answer: string;
  file: string;
  id: number;
};
export type AnswerType4 = {
  correct: string;
  value: string;
};
export type AnswerType5 = {
  answer: string;
};
export type QuizQuestion = {
  id: string;
  id_topic: string;
  main_audio: string;
  paramjs: AnswerType1 | AnswerType2 | AnswerType3[] | AnswerType5;
  qs_dsc: string;
  qs_main: string;
  qs_type: QuestionType;
  isCorrect: boolean;
};

export type Category = {
  id: string;
  name: string;
  level?: string;
};

export type Chapter = {
  id: string;
  id_type: string;
  name: string;
  link_video: string;
  progress?: number;
  text?: string;
};

export interface DragCardItem {
  id: number;
  text: string;
}

export type LevelQuizQuestion = {
  id_item: number;
  numb: number;
  category_id: number;
  text_categorya: string;
  question: string;
  text_vopros: string;
  answer: string;
  options: string[];
};

export type LevelQuizChapter = {
  id_item: number;
  text_categorya: string;
  desc: string;
  text_gl: string;
  audio: string;
};

export type User = {
  user_id: number | null;
  user_level: UserLevel | 'all' | null;
  user_mail: string | null;
  user_sel_level: UserLevel | null;
};
