import React, { DragEventHandler, FC } from 'react';
import { AnswerType3, QuizQuestion } from '../../shared/types';

type Props = {
  data: QuizQuestion;
  isSubmitted: boolean;
  answersState: AnswerType3[];
  setAnswersState: (state: AnswerType3[]) => void;
};

export const CardTemplate8: FC<Props> = ({ data, isSubmitted, answersState, setAnswersState }) => {
  const dragStart: DragEventHandler<HTMLDivElement> = (e) => {
    e.currentTarget.classList.add('tw-opacity-[0.5]');
    e.dataTransfer.setData('index', e.currentTarget.getAttribute('data-dragging-index') as string);
  };
  const dragEnd: DragEventHandler<HTMLDivElement> = (e) => {
    e.currentTarget.classList.remove('tw-opacity-[0.5]');
  };
  const dragDrop: DragEventHandler<HTMLDivElement> = (e) => {
    const draggingItemIndex = Number(e.dataTransfer.getData('index'));
    const dropZoneItemIndex = Number(e.currentTarget.getAttribute('data-dropzone-index'));
    const newState = answersState.map((item, index) => {
      if (index === draggingItemIndex) {
        return { ...item, answer: answersState[dropZoneItemIndex].answer };
      }
      if (index === dropZoneItemIndex) {
        return { ...item, answer: answersState[draggingItemIndex].answer };
      }
      return item;
    });
    setAnswersState(newState as AnswerType3[]);
  };
  const dragOver: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
  };

  return (
    <div className="text-center px-1 px-sm-3 tw-max-w-[900px]">
      <h4 className="h3">{data.qs_main}</h4>
      <div className="my-3 my-sm-5">
        <div className="row gx-1 gx-sm-3 gy-3 tw-items-center">
          {answersState.map((answ, index) => {
            const itemColor = isSubmitted
              ? (data.paramjs as AnswerType3[])[index].answer.toLowerCase().trim() ===
                answersState[index].answer.toLowerCase().trim()
                ? 'bg-success'
                : 'bg-danger'
              : 'bg-white';
            return (
              <>
                <div className="col-6">
                  <div className="tw-flex tw-justify-center">
                    <img src={'https://saryarqatil.kz/edit/lib/pic/' + answ.file} alt={answ.file} width={200} />
                  </div>
                </div>
                <div className="col-6">
                  <div
                    id={'dropzone-' + index}
                    data-dropzone-index={index}
                    className={`px-2 px-sm-5 py-2 tw-min-h-[142.5px] tw-flex tw-flex-col tw-justify-center tw-bg-blue-200`}
                    onDragOver={dragOver}
                    onDrop={dragDrop}
                  >
                    <div
                      id={'answ-' + index}
                      data-dragging-index={index}
                      draggable={!isSubmitted}
                      className={`tw-text-base sm:tw-text-xl border-1 rounded-1 py-2 py-sm-4 px-2 px-sm-3 ${itemColor} ${!isSubmitted ? 'tw-cursor-grabbing tw-border-dashed tw-border-dark tw-text-[#0c276c]' : 'text-white'}`}
                      onDragStart={dragStart}
                      onDragEnd={dragEnd}
                    >
                      {answ.answer}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};
