import React, { useEffect, useState } from 'react';
import { Category, Chapter } from '../../shared/types';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './chaptersPage.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ChaptersPage = () => {
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const [category, setCategory] = useState<Category>();
  const [chapters, setChapters] = useState<Chapter[]>([]);

  useEffect(() => {
    (async () => {
      fetch('https://saryarqatil.kz/edit/admin/jsoncore.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          command: 'update_list_type',
          tmp_table: 'tbl_type',
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          const category = data.find((item: Category) => item.id === categoryId);
          setCategory(category);
        });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      fetch('https://saryarqatil.kz/edit/admin/jsoncore.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          command: 'update_list_type',
          tmp_id: categoryId,
          tmp_table: 'tbl_topic',
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setChapters(data);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container tw-pt-[20px] sm:tw-pt-[36px]">
      <button
        type="button"
        className="btn btn-outline-secondary btn-lg !tw-text-base sm:!tw-text-xl mb-3"
        onClick={() => navigate(-1)}
      >
        <i className="bi bi-chevron-left tw-mr-2"></i>
        Кері оралу
      </button>
      <div className={styles.headCard}>{category?.name?.toUpperCase()}</div>
      <div className="row">
        {chapters.length > 0 ? (
          chapters.map((chapter, idx) => {
            const isDisabled = chapters[idx - 1]?.progress! < 50;
            // const isPassed = Cookies.get(`chapter-${chapter.id}`);
            const isStarted = chapter?.progress! !== 0;
            const isPassed = chapter?.progress! > 50;
            return (
              <div key={chapter.id} className="col-12 col-md-6 col-lg-4 mb-2 mb-sm-4">
                {/*<OverlayTrigger*/}
                {/*  placement="bottom"*/}
                {/*  delay={{ show: 500, hide: 400 }}*/}
                {/*  overlay={*/}
                {/*    <Tooltip id={`card-tooltip-${chapter.id}`}>*/}
                {/*      {isDisabled ? 'Қолжетімсіз' : `${chapter.progress}% орындалды`}*/}
                {/*    </Tooltip>*/}
                {/*  }*/}
                {/*>*/}
                {/*  <div*/}
                {/*    className={`card ${styles.cardCustom} ${isDisabled ? '!tw-opacity-70' : ''}`}*/}
                {/*    key={chapter.id}*/}
                {/*    onClick={() => {*/}
                {/*      if (!isDisabled) {*/}
                {/*        navigate(chapter.id);*/}
                {/*      }*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    <div*/}
                {/*      className={`${styles.cardBodyCustom} card-body ${isDisabled ? '!tw-border-t-gray-500' : isStarted ? (isPassed ? '!tw-border-t-[#28a745]' : '!tw-border-t-[#dc3545]') : '!tw-border-t-[#3490dc]'}`}*/}
                {/*    >*/}
                {/*      {chapter.name?.toUpperCase()}*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</OverlayTrigger>*/}
                <div
                  className={`card ${styles.cardCustom} ${isDisabled ? '!tw-opacity-70' : ''}`}
                  key={chapter.id}
                  onClick={() => {
                    if (!isDisabled) {
                      navigate(chapter.id);
                    }
                  }}
                >
                  <div
                    className={`${styles.cardBodyCustom} card-body ${isDisabled ? '!tw-border-t-gray-500' : isStarted ? (isPassed ? '!tw-border-t-[#28a745]' : '!tw-border-t-[#dc3545]') : '!tw-border-t-[#3490dc]'}`}
                  >
                    {chapter.name?.toUpperCase()}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-center h5 pt-3">деректер жоқ</div>
        )}
      </div>
    </div>
  );
};

export default ChaptersPage;
