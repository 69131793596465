import React, { FC, useEffect } from 'react';
import { QuizQuestion } from '../../shared/types';

type Props = {
  data: QuizQuestion;
  isSubmitted: boolean;
  currentAnswer: string;
  setCurrentAnswer: (val: string) => void;
  pronounceAudio: (isQuestionPlay?: boolean) => void;
  pauseAudio: () => void;
  isAudioPlaying: boolean;
};

export const CardTemplate4: FC<Props> = ({
  data,
  isSubmitted,
  currentAnswer,
  setCurrentAnswer,
  pronounceAudio,
  pauseAudio,
  isAudioPlaying,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentAnswer(e.target.value);
  };

  useEffect(() => {
    pronounceAudio(true);
  }, [data.id]);

  return (
    <div className="text-center">
      <h4 className="h3">{data.qs_main}</h4>
      <div className="p-2 p-sm-3 my-3 my-sm-4">
        <div className="text-center">
          <i
            className="bi bi-headphones tw-text-[#0c276c] tw-text-4xl sm:tw-text-5xl tw-cursor-pointer hover:tw-opacity-80"
            onClick={() => pronounceAudio(true)}
          ></i>
        </div>
        <div className="mt-3 text-center">
          <input
            disabled={isSubmitted}
            spellCheck={false}
            className="tw-w-full tw-h-[38px] sm:tw-h-[48px] px-2 py-1 text-center tw-text-xl sm:tw-text-2xl border border-1 border-gray focus-visible:tw-outline-[#0d6efd] tw-bg-white rounded-1"
            value={currentAnswer}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};
