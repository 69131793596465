import React, { ChangeEvent, FC } from 'react';
import { AnswerType3, QuizQuestion } from '../../shared/types';
import quizPageStyles from '../../pages/quizPage/quizPage.module.scss';

type Props = {
  data: QuizQuestion;
  isSubmitted: boolean;
  answersState: AnswerType3[];
  setAnswersState: (state: AnswerType3[]) => void;
};

export const CardTemplate6: FC<Props> = ({ data, isSubmitted, answersState, setAnswersState }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    const newState = answersState.map((item) => {
      if (item.id === id) {
        return { ...item, answer: e.target.value };
      } else return item;
    });
    setAnswersState(newState);
  };
  return (
    <div className="text-center px-1 px-sm-3 tw-max-w-[900px]">
      <h4 className="h3">{data.qs_main}</h4>
      <div className="my-4">
        <div className="row gx-0 gx-sm-3 gy-2 gy-sm-3 align-items-center">
          {answersState.map((answ) => {
            const originalAnswer = (data.paramjs as AnswerType3[]).find((item) => item.id === answ.id)?.answer;
            const inputColor = isSubmitted
              ? originalAnswer?.toLowerCase().trim() === answ.answer.toLowerCase().trim()
                ? 'bg-success text-white'
                : 'bg-danger text-white'
              : 'bg-white tw-text-[#0c276c]';
            return (
              <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                <div className="card p-2 shadow-sm">
                  <div className="tw-flex tw-justify-center mb-3">
                    <img src={'https://saryarqatil.kz/edit/lib/pic/' + answ.file} alt={answ.file} width={200} />
                  </div>
                  <div>
                    <input
                      disabled={isSubmitted}
                      spellCheck={false}
                      className={`${quizPageStyles.coloredInput} w-100 tw-h-[34px] px-3 text-center rounded-1 border border-1 border-gray focus-visible:tw-outline-[#0d6efd] tw-text-lg ${inputColor}`}
                      value={answ.answer}
                      onChange={(e) => handleChange(e, answ.id)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
