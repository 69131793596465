import React, { FC } from 'react';
import { QuizQuestion } from '../../shared/types';
import Select from 'react-dropdown-select';

type Props = {
  data: QuizQuestion;
  isSubmitted: boolean;
  currentAnswer: string;
  setCurrentAnswer: (val: string) => void;
};

export const CardTemplate10: FC<Props> = ({ data, isSubmitted, currentAnswer, setCurrentAnswer }) => {
  const splittedQuestion = data.qs_dsc?.split('*');
  const options = Object.values(data.paramjs);

  return (
    <div className="text-center">
      <h4 className="h3">{data.qs_main}</h4>
      <div className="my-5 tw-flex tw-justify-center tw-items-center tw-flex-wrap gap-2">
        <div className="tw-flex tw-justify-center tw-items-center tw-flex-nowrap">
          <p className="tw-text-xl sm:tw-text-2xl mb-0">{splittedQuestion?.[0]}</p>
          {/*// @ts-ignore*/}
          <Select
            disabled={isSubmitted}
            valueField="value"
            labelField="value"
            onChange={(newValue) => setCurrentAnswer(newValue[0].value)}
            options={Object.values(data.paramjs)}
            className={`tw-h-[38px] tw-text-xl sm:tw-text-2xl [&_.react-dropdown-select-dropdown-handle]:tw-h-[40px] [&_.react-dropdown-select-dropdown]:tw-w-auto`}
            searchable={false}
            placeholder=""
            closeOnSelect
          />
        </div>
        <p className="tw-text-xl sm:tw-text-2xl mb-0">{splittedQuestion?.[1]}</p>
      </div>

      {/*<div*/}
      {/*  className="btn-group tw-w-full my-4 my-sm-5 tw-flex tw-justify-center tw-flex-col min-[400px]:tw-flex-row gap-3"*/}
      {/*  role="group"*/}
      {/*  aria-label="Basic radio toggle button group"*/}
      {/*>*/}
      {/*  {Object.values(data.paramjs).map((answ) => {*/}
      {/*    const isAnswerCorrect = answ.correct === 'true';*/}
      {/*    return (*/}
      {/*      <div*/}
      {/*        className={*/}
      {/*          `rounded-1 py-1 px-3 tw-text-xl sm:tw-text-2xl mb-0 ${!isSubmitted ? 'tw-cursor-pointer' : ''} ` +*/}
      {/*          (isSubmitted && answ.value === currentAnswer*/}
      {/*            ? isAnswerCorrect*/}
      {/*              ? 'bg-success border-0 text-white'*/}
      {/*              : 'bg-danger text-white border-0'*/}
      {/*            : 'border border-1 border-gray bg-white tw-text-[#0c276c]')*/}
      {/*        }*/}
      {/*        onClick={() => {*/}
      {/*          if (!isSubmitted) {*/}
      {/*            setCurrentAnswer(answ.value);*/}
      {/*          }*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        {answ.value}*/}
      {/*      </div>*/}
      {/*    );*/}
      {/*  })}*/}
      {/*</div>*/}
    </div>
  );
};
