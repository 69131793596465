import React, { useContext, useEffect, useState } from 'react';
import { Category, UserLevel } from '../../shared/types';
import { useNavigate } from 'react-router-dom';
import styles from './categoriesPage.module.scss';
import { categoryTitles } from '../../shared/constants';
import { UserContext } from '../../App';

const CategoriesPage = () => {
  const navigate = useNavigate();
  const { currentUser, selectedLevel } = useContext(UserContext);
  const [categories, setCategories] = useState<Category[]>([]);

  const selLevel = selectedLevel ?? currentUser?.user_sel_level ?? 'A1';

  useEffect(() => {
    (async () => {
      fetch('https://saryarqatil.kz/edit/admin/jsoncore.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          command: 'update_list_type',
          tmp_table: 'tbl_type',
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          const filteredA2Data = data.filter((item: Category) => item.level === selLevel);
          setCategories(filteredA2Data);
        });
    })();
  }, []);

  return (
    <div className="container tw-pt-[20px] sm:tw-pt-[100px]">
      <div className={styles.headCard}>{categoryTitles[selLevel]}</div>
      <div className="row">
        {categories.map((cat) => (
          <div key={cat.id} className="col-12 col-md-6 col-lg-4 tw-mb-2 sm:tw-mb-4">
            <div className={`card ${styles.cardCustom}`} onClick={() => navigate(`${cat.id}/chapters`)}>
              <div className={`${styles.cardBodyCustom} card-body`}>{cat.name.toUpperCase()}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoriesPage;
