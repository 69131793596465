import React, { FC } from 'react';
import { QuizQuestion } from '../../shared/types';
import Form from 'react-bootstrap/Form';

type Props = {
  data: QuizQuestion;
  isSubmitted: boolean;
  currentAnswer: string;
  setCurrentAnswer: (val: string) => void;
};

export const CardTemplate9: FC<Props> = ({ data, isSubmitted, currentAnswer, setCurrentAnswer }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (e.target.checked) {
      setCurrentAnswer(value);
      setCurrentAnswer(value);
    }
  };

  return (
    <div className="text-center">
      <h4 className="h3">{data.qs_main}</h4>
      <p className="my-4 my-sm-5 tw-text-xl sm:tw-text-2xl">{data.qs_dsc.replace('*', '＿')}</p>

      <div className="my-4 d-flex justify-content-center">
        <div className="d-flex flex-column tw-items-start gap-2">
          {Object.values(data.paramjs).map((answ) => (
            <Form.Check // prettier-ignore
              type="checkbox"
              id={`answ-${answ.value}`}
              label={answ.value}
              className="!tw-text-xl sm:!tw-text-2xl"
              disabled={isSubmitted && answ.value !== currentAnswer}
              onChange={(e) => handleChange(e, answ.value)}
              checked={currentAnswer === answ.value}
            />
          ))}
        </div>
      </div>

      {/*<div*/}
      {/*  className="btn-group tw-w-full my-4 my-sm-5 tw-flex tw-justify-around gap-3 tw-max-w-[600px] tw-mx-auto tw-flex-col min-[400px]:tw-flex-row"*/}
      {/*  role="group"*/}
      {/*  aria-label="Basic radio toggle button group"*/}
      {/*>*/}
      {/*  {Object.values(data.paramjs).map((answ) => {*/}
      {/*    const isAnswerCorrect = answ.correct === 'true';*/}
      {/*    return (*/}
      {/*      <React.Fragment key={answ.value + data.id}>*/}
      {/*        <input*/}
      {/*          type="radio"*/}
      {/*          className="btn-check"*/}
      {/*          name="btnradio"*/}
      {/*          id={'cardAnswer-' + answ.value}*/}
      {/*          autoComplete="off"*/}
      {/*          onChange={(e) => handleChange(e, answ.value)}*/}
      {/*          disabled={isSubmitted && answ.value !== currentAnswer}*/}
      {/*        />*/}
      {/*        <label*/}
      {/*          className={*/}
      {/*            'btn rounded-2 !tw-text-xl sm:!tw-text-2xl tw-min-w-[90px] !tw-text-[#0c276c] ' +*/}
      {/*            (answ.value === currentAnswer*/}
      {/*              ? isSubmitted*/}
      {/*                ? isAnswerCorrect*/}
      {/*                  ? 'btn-success !tw-text-white'*/}
      {/*                  : 'btn-danger !tw-text-white'*/}
      {/*                : '!tw-bg-[#0c276c] !tw-text-white'*/}
      {/*              : '!border !tw-border-[#0c276c]')*/}
      {/*          }*/}
      {/*          htmlFor={'cardAnswer-' + answ.value}*/}
      {/*        >*/}
      {/*          {answ.value}*/}
      {/*        </label>*/}
      {/*      </React.Fragment>*/}
      {/*    );*/}
      {/*  })}*/}
      {/*</div>*/}
    </div>
  );
};
