function shuffle(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export const shuffleAnswersType5 = (questions: any[]) => {
  const answers = questions.map((q) => q.correct);

  shuffle(answers);
  const incorrectAnswers = [...answers];

  const firstAnswer = incorrectAnswers.shift();
  incorrectAnswers.push(firstAnswer as string);

  return questions.map((q, index) => ({
    ...q,
    correct: incorrectAnswers[index],
  }));
};

export const shuffleAnswersType8 = (questions: any[]) => {
  const answers = questions.map((q) => q.answer);

  shuffle(answers);
  const incorrectAnswers = [...answers];

  const firstAnswer = incorrectAnswers.shift();
  incorrectAnswers.push(firstAnswer as string);

  return questions.map((q, index) => ({
    ...q,
    answer: incorrectAnswers[index],
  }));
};

export const getId = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};

export const levenshteinDistance = (a: string, b: string) => {
  const matrix = [];

  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) == a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1));
      }
    }
  }

  return matrix[b.length][a.length];
};

export const compareText = (recognizedText: string, expectedText: string) => {
  const trimmedRecognizedText = recognizedText.trim().toLowerCase();
  const trimmedExpectedText = expectedText.trim().toLowerCase();
  const distance = levenshteinDistance(trimmedRecognizedText, trimmedExpectedText);
  const maxLength = Math.max(trimmedRecognizedText.length, trimmedExpectedText.length);
  const similarity = Math.round(((maxLength - distance) / maxLength) * 100);
  return similarity > 50;
};

export const findTwoStringsIntersection = (promptText: string, expectedText: string) => {
  const promptTextSet = new Set(
    promptText
      .toLowerCase()
      .trim()
      .split(',')
      .map((item) => item.trim())
  );
  const expectedTextSet = new Set(
    expectedText
      .toLowerCase()
      .trim()
      .split(',')
      .map((item) => item.trim())
  );

  // @ts-ignore
  const intersection = new Set([...promptTextSet].filter((word) => expectedTextSet.has(word)));
  return (intersection.size * 100) / expectedTextSet.size > 50;
};
