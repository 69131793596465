import React, { useContext } from 'react';
import { UserContext } from '../../App';
import { Navigate, Outlet } from 'react-router-dom';

export const ProtectedRoute = () => {
  const { currentUser } = useContext(UserContext);

  if (!!currentUser) {
    return <Outlet />;
  }
  return <Navigate to="/" />;
};
