import React, { FC, useEffect, useState } from 'react';
import { AnswerType3, AnswerType4, QuestionType, QuizQuestion } from '../../shared/types';
import { CardTemplate5 } from '../cardTemplates/cardTemplate5';
import { CardTemplate8 } from '../cardTemplates/cardTemplate8';
import { CardTemplate6 } from '../cardTemplates/cardTemplate6';
import { CardTemplate7 } from '../cardTemplates/cardTemplate7';
import { shuffleAnswersType5, shuffleAnswersType8 } from '../../shared/utils';

type Props = {
  data: QuizQuestion;
  handleAnswer: (id: string, isCorrect: boolean) => void;
  isSubmitted: boolean;
  handleChangeQuestion: () => void;
};
export const MultipleAnswerCard: FC<Props> = ({ data, handleAnswer, handleChangeQuestion, isSubmitted }) => {
  const isType5 = data.qs_type === QuestionType.Five;
  const isType6 = data.qs_type === QuestionType.Six;
  const isType7 = data.qs_type === QuestionType.Seven;
  const isType8 = data.qs_type === QuestionType.Eight;

  const [answersState, setAnswersState] = useState<AnswerType3[]>([]);
  const [type5AnswersState, setType5AnswersState] = useState<AnswerType4[]>([]);

  const checkIsIncorrect = () => {
    if (isType5) {
      const values = Object.values(data.paramjs);
      return values.some(
        (item, index) => item.correct.toLowerCase().trim() !== type5AnswersState[index].correct.toLowerCase().trim()
      );
    } else {
      const values = data.paramjs as AnswerType3[];
      return values.some(
        (item, index) => item.answer.toLowerCase().trim() !== answersState[index].answer.toLowerCase().trim()
      );
    }
  };

  const handleSubmit = () => {
    const isIncorrect = checkIsIncorrect();
    handleAnswer(data.id, !isIncorrect);
  };

  useEffect(() => {
    if (isType5) {
      const values = Object.values(data.paramjs);
      const mixedValues = shuffleAnswersType5(values);
      setType5AnswersState(mixedValues);
    } else if (isType8) {
      const values = data.paramjs as AnswerType3[];
      const mixedValues = shuffleAnswersType8(values);
      setAnswersState(mixedValues);
    } else {
      setAnswersState((data.paramjs as AnswerType3[]).map((item) => ({ ...item, answer: '' })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.id]);

  return (
    <div className="py-4 px-2 px-sm-4 rounded-2 text-center bg-white tw-shadow-[0_0_50px_-12px_rgb(0,0,0,0.4)]">
      {isType5 && (
        <CardTemplate5
          data={data}
          isSubmitted={isSubmitted}
          answersState={type5AnswersState}
          setAnswersState={setType5AnswersState}
        />
      )}
      {isType6 && (
        <CardTemplate6
          data={data}
          isSubmitted={isSubmitted}
          answersState={answersState}
          setAnswersState={setAnswersState}
        />
      )}
      {isType7 && (
        <CardTemplate7
          data={data}
          isSubmitted={isSubmitted}
          answersState={answersState}
          setAnswersState={setAnswersState}
        />
      )}
      {isType8 && (
        <CardTemplate8
          data={data}
          isSubmitted={isSubmitted}
          answersState={answersState}
          setAnswersState={setAnswersState}
        />
      )}
      {!isSubmitted ? (
        <button
          className="btn btn-primary px-5 font-bold tw-w-full min-[400px]:tw-w-[220px] sm:!tw-text-xl"
          onClick={handleSubmit}
        >
          ТЕКСЕРУ
        </button>
      ) : (
        <button
          className="btn btn-outline-primary px-5 font-bold tw-w-full min-[400px]:tw-w-[220px] sm:!tw-text-xl"
          onClick={handleChangeQuestion}
        >
          КЕЛЕСІ
        </button>
      )}
    </div>
  );
};
