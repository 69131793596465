import { UserLevel } from '../../shared/types';

export const learningLevels = [
  {
    id: 1,
    level: UserLevel.A1,
    frontTitle: 'A1',
    frontDesc: 'Қарапайым деңгей',
    backTitle: 'A1 Деңгейі',
    enabledForLevels: [UserLevel.A1, UserLevel.A2, UserLevel.B1, UserLevel.B2, UserLevel.C1],
  },
  {
    id: 1,
    level: UserLevel.A2,
    frontTitle: 'A2',
    frontDesc: 'Базалық деңгей',
    backTitle: 'A2 Деңгейі',
    enabledForLevels: [UserLevel.A2, UserLevel.B1, UserLevel.B2, UserLevel.C1],
  },
  {
    id: 1,
    level: UserLevel.B1,
    frontTitle: 'B1',
    frontDesc: 'Орта деңгей',
    backTitle: 'B1 Деңгейі',
    enabledForLevels: [UserLevel.B1, UserLevel.B2, UserLevel.C1],
  },
  {
    id: 1,
    level: UserLevel.B2,
    frontTitle: 'B2',
    frontDesc: 'Ортадан жоғары деңгей',
    backTitle: 'B2 Деңгейі',
    enabledForLevels: [UserLevel.B2, UserLevel.C1],
  },
  {
    id: 1,
    level: UserLevel.C1,
    frontTitle: 'C1',
    frontDesc: 'Жоғары деңгей',
    backTitle: 'C1 Деңгейі',
    enabledForLevels: [UserLevel.C1],
  },
];

export const levelsArr = ['A1', 'A2', 'B1', 'B2', 'C1'];
export const bookImages = ['A1', 'A2', 'B1', 'B2', 'C1'];
