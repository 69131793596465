import React, { FC } from 'react';
import { QuizQuestion } from '../../shared/types';

type Props = {
  data: QuizQuestion;
  isSubmitted: boolean;
  currentAnswer: string;
  setCurrentAnswer: (val: string) => void;
};

export const CardTemplate13: FC<Props> = ({ data, isSubmitted, currentAnswer, setCurrentAnswer }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentAnswer(e.target.value);
  };

  return (
    <div className="text-center">
      <h4 className="h3">{data.qs_main}</h4>
      <p className="mt-4 mt-sm-5 tw-text-xl sm:tw-text-2xl">{data.qs_dsc}</p>
      <p className="mt-2 mb-4 mb-sm-5 tw-text-lg sm:tw-text-xl">(Жауаптарыңызды үтір арқылы еңгізіңіз)</p>
      <div className="tw-mb-5">
        <input
          disabled={isSubmitted}
          spellCheck={false}
          className="tw-w-full tw-h-[48px] text-center tw-text-xl sm:tw-text-2xl border border-1 border-gray bg-white rounded-1 px-2 py-1"
          value={currentAnswer}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};
