import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AnswerType2, AnswerType3, AnswerType5, QuestionType, QuizQuestion } from '../../shared/types';
import { CardTemplate1 } from '../cardTemplates/cardTemplate1';
import { CardTemplate2 } from '../cardTemplates/cardTemplate2';
import { CardTemplate3 } from '../cardTemplates/cardTemplate3';
import { CardTemplate4 } from '../cardTemplates/cardTemplate4';
import quizPageStyles from '../../pages/quizPage/quizPage.module.scss';
import { CardTemplate9 } from '../cardTemplates/cardTemplate9';
import { CardTemplate10 } from '../cardTemplates/cardTemplate10';
import { CardTemplate11 } from '../cardTemplates/cardTemplate11';
import { compareText, findTwoStringsIntersection } from '../../shared/utils';
import { CardTemplate13 } from '../cardTemplates/cardTemplate13';

type Props = {
  data: QuizQuestion;
  handleAnswer: (id: string, isCorrect: boolean) => void;
  isSubmitted: boolean;
  handleChangeQuestion: () => void;
};
export const SingleAnswerCard: FC<Props> = ({ data, handleAnswer, isSubmitted, handleChangeQuestion }) => {
  const isType4 = data.qs_type === QuestionType.Four;
  const type4Answer = isType4 ? (data.paramjs as AnswerType2)['1'].correct : undefined;
  const isVoiceRecognitionQuestion = [QuestionType.Eleven, QuestionType.Twelve].includes(data?.qs_type);
  const voiceRecognitionQuestionAnswer = data?.qs_dsc;
  const isTextRecognitionQuestion = data.qs_type === QuestionType.Thirteen;
  const textRecognitionQuestionAnswer = (data.paramjs as AnswerType5)?.answer;
  const otherTypesAnswer = data?.paramjs
    ? Object.values(data?.paramjs)?.find((item) => item.correct === 'true')?.value
    : '';
  const [currentAnswer, setCurrentAnswer] = useState<string>('');
  const [isAudioPlaying, setIsAudioPlaying] = useState<boolean>(false);
  const cardAudio = useRef<HTMLAudioElement>(null);

  const isCorrect = useMemo(() => {
    if (isTextRecognitionQuestion) return findTwoStringsIntersection(currentAnswer, textRecognitionQuestionAnswer);
    if (isVoiceRecognitionQuestion) return compareText(currentAnswer, voiceRecognitionQuestionAnswer);
    if (isType4) return currentAnswer.toLowerCase().trim() === type4Answer?.toLowerCase().trim();
    return currentAnswer.toLowerCase().trim() === otherTypesAnswer.toLowerCase().trim();
  }, [currentAnswer]);

  const finalCorrectText = useMemo(() => {
    if (isTextRecognitionQuestion) return textRecognitionQuestionAnswer;
    if (isVoiceRecognitionQuestion) return voiceRecognitionQuestionAnswer;
    if (isType4) return type4Answer;
    if (!!data?.qs_dsc && data?.qs_dsc?.includes('*'))
      return data.qs_dsc.replace('*', otherTypesAnswer.replace('-', ''));
    return otherTypesAnswer;
  }, [data]);

  const pronounceAudio = (isQuestionPlay = false) => {
    const resp = cardAudio.current?.play();
    if (resp !== undefined) {
      resp
        .then((_) => {
          // autoplay starts!
          if (isQuestionPlay) {
            setIsAudioPlaying(true);
          }
        })
        .catch(() => {
          //show error
        });
    }
  };

  const pauseAudio = () => cardAudio.current?.pause();

  const handleSubmit = () => {
    pronounceAudio();
    handleAnswer(data.id, isCorrect);
  };

  useEffect(() => {
    setCurrentAnswer('');
  }, [data.id]);

  return (
    <div className="py-4 px-2 px-sm-4 rounded-2 text-center bg-white tw-shadow-[0_0_50px_-12px_rgb(0,0,0,0.4)] sm:tw-min-w-[600px] md:tw-min-w-[700px] md:tw-min-h-[600px] md:tw-flex tw-justify-center tw-items-center tw-flex-grow-[1]">
      <div className="md:tw-min-w-[80%]">
        <audio
          ref={cardAudio}
          hidden
          src={'https://saryarqatil.kz/edit/lib/audio/' + data.main_audio}
          onPause={() => setIsAudioPlaying(false)}
        />
        {data.qs_type === QuestionType.One && (
          <CardTemplate1
            data={data}
            isSubmitted={isSubmitted}
            currentAnswer={currentAnswer}
            setCurrentAnswer={setCurrentAnswer}
          />
        )}
        {data.qs_type === QuestionType.Two && (
          <CardTemplate2
            data={data}
            isSubmitted={isSubmitted}
            currentAnswer={currentAnswer}
            setCurrentAnswer={setCurrentAnswer}
          />
        )}
        {data.qs_type === QuestionType.Three && (
          <CardTemplate3
            data={data}
            isSubmitted={isSubmitted}
            currentAnswer={currentAnswer}
            setCurrentAnswer={setCurrentAnswer}
            pronounceAudio={pronounceAudio}
            isAudioPlaying={isAudioPlaying}
            pauseAudio={pauseAudio}
          />
        )}
        {isType4 && (
          <CardTemplate4
            data={data}
            isSubmitted={isSubmitted}
            currentAnswer={currentAnswer}
            setCurrentAnswer={setCurrentAnswer}
            pronounceAudio={pronounceAudio}
            isAudioPlaying={isAudioPlaying}
            pauseAudio={pauseAudio}
          />
        )}
        {data.qs_type === QuestionType.Nine && (
          <CardTemplate9
            data={data}
            isSubmitted={isSubmitted}
            currentAnswer={currentAnswer}
            setCurrentAnswer={setCurrentAnswer}
          />
        )}
        {data.qs_type === QuestionType.Ten && (
          <CardTemplate10
            data={data}
            isSubmitted={isSubmitted}
            currentAnswer={currentAnswer}
            setCurrentAnswer={setCurrentAnswer}
          />
        )}
        {data.qs_type === QuestionType.Eleven && (
          <CardTemplate11
            data={data}
            isSubmitted={isSubmitted}
            currentAnswer={currentAnswer}
            setCurrentAnswer={setCurrentAnswer}
          />
        )}

        {data.qs_type === QuestionType.Twelve && (
          <CardTemplate11
            data={data}
            isSubmitted={isSubmitted}
            currentAnswer={currentAnswer}
            setCurrentAnswer={setCurrentAnswer}
            pronounceAudio={pronounceAudio}
          />
        )}

        {data.qs_type === QuestionType.Thirteen && (
          <CardTemplate13
            data={data}
            isSubmitted={isSubmitted}
            currentAnswer={currentAnswer}
            setCurrentAnswer={setCurrentAnswer}
          />
        )}

        {isSubmitted && (
          <div
            className={
              'tw-flex tw-justify-center tw-items-center gap-2 border border-1 border-gray p-3 ' +
              (isCorrect ? 'bg-success' : 'bg-danger')
            }
          >
            <p className={`${quizPageStyles.correctAnsw} text-white mb-1 tw-text-xl sm:!tw-text-2xl`}>
              {finalCorrectText}
            </p>
            <i
              className={
                'bi bi-volume-up-fill text-white tw-text-3xl tw-cursor-pointer hover:tw-scale-125 transition ease-in-out delay-150'
              }
              onClick={() => pronounceAudio()}
            ></i>
          </div>
        )}

        {!isSubmitted ? (
          <button
            className="btn btn-primary mt-3 px-5 tw-font-bold tw-w-full min-[400px]:tw-w-[220px] sm:!tw-text-xl"
            onClick={handleSubmit}
          >
            ТЕКСЕРУ
          </button>
        ) : (
          <button
            className="btn btn-outline-primary mt-3 px-5 font-bold tw-w-full min-[400px]:tw-w-[220px] sm:!tw-text-xl"
            onClick={handleChangeQuestion}
          >
            КЕЛЕСІ
          </button>
        )}
      </div>
    </div>
  );
};
