import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { AnswerType3, QuizQuestion } from '../../shared/types';
import quizPageStyles from '../../pages/quizPage/quizPage.module.scss';

type Props = {
  data: QuizQuestion;
  isSubmitted: boolean;
  answersState: AnswerType3[];
  setAnswersState: (state: AnswerType3[]) => void;
};

const AudioQuestion: FC<{ answer: AnswerType3 }> = ({ answer }) => {
  const audio = useRef<HTMLAudioElement>(null);
  const [isAudioPlaying, setIsAudioPlaying] = useState<boolean>(false);

  const pronounceAudio = () => {
    const resp = audio.current?.play();
    if (resp !== undefined) {
      resp
        .then((_) => {
          // autoplay starts!
          setIsAudioPlaying(true);
        })
        .catch((error) => {
          // show error
        });
    }
  };

  const pauseAudio = () => audio.current?.pause();

  return (
    <div>
      <audio
        ref={audio}
        hidden
        src={'https://saryarqatil.kz/edit/lib/audio/' + answer.file}
        onPause={() => setIsAudioPlaying(false)}
      />
      <i
        className="bi bi-headphones tw-text-[#0c276c] tw-text-4xl sm:tw-text-5xl cursor-pointer hover:opacity-80"
        onClick={pronounceAudio}
      ></i>
    </div>
  );
};

export const CardTemplate7: FC<Props> = ({ data, isSubmitted, answersState, setAnswersState }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    const newState = answersState.map((item) => {
      if (item.id === id) {
        return { ...item, answer: e.target.value };
      } else return item;
    });
    setAnswersState(newState);
  };

  return (
    <div className="text-center px-1 px-sm-3 tw-max-w-[600px]">
      <h4 className="h3">{data.qs_main}</h4>
      <div className="my-4">
        <div className="row gx-0 gx-sm-4 gy-3 gy-sm-4 tw-items-center">
          {answersState.map((answ) => {
            const inputColor = isSubmitted
              ? (data.paramjs as AnswerType3[])
                  .find((item) => item.id === answ.id)
                  ?.answer.toLowerCase()
                  .trim() === answ.answer.toLowerCase().trim()
                ? 'bg-success text-white'
                : 'bg-danger text-white'
              : 'bg-white tw-text-[#0c276c]';

            return (
              <div key={answ.id} className="col-12 col-sm-6">
                <div className="card p-2 pt-3 shadow-sm gap-3">
                  <AudioQuestion answer={answ} />
                  <div className="flex-grow-1">
                    <input
                      disabled={isSubmitted}
                      spellCheck={false}
                      className={`${quizPageStyles.coloredInput} w-100 tw-h-[34px] px-3 text-center rounded-1 border border-1 border-gray focus-visible:tw-outline-[rgb(0,95,204)] tw-text-lg ${inputColor}`}
                      value={answ.answer}
                      onChange={(e) => handleChange(e, answ.id)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
