import React, { DragEventHandler, FC } from 'react';
import { AnswerType1, AnswerType4, QuizQuestion } from '../../shared/types';

type Props = {
  data: QuizQuestion;
  isSubmitted: boolean;
  answersState: AnswerType4[];
  setAnswersState: (state: AnswerType4[]) => void;
};

export const CardTemplate5: FC<Props> = ({ data, isSubmitted, answersState, setAnswersState }) => {
  const dragStart: DragEventHandler<HTMLDivElement> = (e) => {
    e.currentTarget.classList.add('tw-opacity-[0.5]');
    e.dataTransfer.setData('index', e.currentTarget.getAttribute('data-dragging-index') as string);
  };
  const dragEnd: DragEventHandler<HTMLDivElement> = (e) => {
    e.currentTarget.classList.remove('tw-opacity-[0.5]');
  };
  const dragDrop: DragEventHandler<HTMLDivElement> = (e) => {
    const draggingItemIndex = Number(e.dataTransfer.getData('index'));
    const dropZoneItemIndex = Number(e.currentTarget.getAttribute('data-dropzone-index'));
    const newState = answersState.map((item, index) => {
      if (index === draggingItemIndex) {
        return { ...item, correct: answersState[dropZoneItemIndex].correct };
      }
      if (index === dropZoneItemIndex) {
        return { ...item, correct: answersState[draggingItemIndex].correct };
      }
      return item;
    });
    setAnswersState(newState as AnswerType4[]);
  };
  const dragOver: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
  };

  return (
    <div className="text-center px-3">
      <h5 className="h3">{data.qs_main}</h5>
      <div className="my-3 my-sm-5 tw-flex tw-flex-col gap-3">
        <div className="row gx-2 gx-sm-3 gy-3 tw-items-center">
          {answersState.map((answ, index) => {
            const itemColor = isSubmitted
              ? (data.paramjs as AnswerType1)[`${index + 1}`].correct === answersState[index].correct
                ? 'bg-success'
                : 'bg-danger'
              : 'bg-white';
            return (
              <>
                <div className="col-6">
                  <div className="border border-1 border-gray bg-white rounded-1 p-2 px-3 tw-text-lg sm:tw-text-2xl tw-text-nowrap text-center tw-text-[#0c276c]">
                    {answ.value}
                  </div>
                </div>
                <div className="col-6">
                  <div
                    id={'dropzone-' + index}
                    data-dropzone-index={index}
                    className="px-sm-3 py-1 tw-flex tw-flex-col tw-justify-center"
                    onDragOver={dragOver}
                    onDrop={dragDrop}
                  >
                    <div
                      id={'answ-' + index}
                      data-dragging-index={index}
                      draggable={!isSubmitted}
                      className={`tw-text-lg sm:tw-text-2xl border-1 rounded-1 p-2 px-3 ${itemColor} ${!isSubmitted ? 'tw-cursor-grabbing tw-border-dashed tw-border-dark tw-text-[#0c276c]' : 'text-white'}`}
                      onDragStart={dragStart}
                      onDragEnd={dragEnd}
                    >
                      {answ.correct}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};
