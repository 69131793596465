import React, { FC, useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { QuizQuestion } from '../../shared/types';

type Props = {
  data: QuizQuestion;
  isSubmitted: boolean;
  currentAnswer: string;
  setCurrentAnswer: (val: string) => void;
  pronounceAudio?: (isQuestionPlay?: boolean) => void;
};

export const CardTemplate11: FC<Props> = ({ data, isSubmitted, setCurrentAnswer, pronounceAudio }) => {
  const { transcript, listening, browserSupportsSpeechRecognition } = useSpeechRecognition();
  SpeechRecognition.getRecognition()?.addEventListener('result', () => {
    setCurrentAnswer(transcript ?? '');
  });

  const handleMicClick = () => {
    if (isSubmitted) return;
    if (listening) {
      SpeechRecognition.stopListening().then(() => {
        setCurrentAnswer(transcript ?? '');
      });
    } else {
      SpeechRecognition.startListening({ language: 'kk-KZ', interimResults: false });
    }
  };

  const handlePronounceAudio = () => {
    if (pronounceAudio) {
      pronounceAudio(true);
    }
  };

  useEffect(() => {
    handlePronounceAudio();
  }, [data.id]);

  return (
    <div className="text-center">
      <h4 className="h3">{data.qs_main}</h4>
      <div className="my-4 tw-flex tw-justify-center tw-items-center tw-flex-wrap gap-2">
        {!!pronounceAudio && (
          <i
            className="bi bi-headphones tw-text-[#0c276c] tw-text-4xl tw-cursor-pointer hover:tw-opacity-80"
            onClick={handlePronounceAudio}
          ></i>
        )}
        <p className="my-sm-5 tw-text-xl sm:tw-text-2xl">{data.qs_dsc}</p>
      </div>
      {browserSupportsSpeechRecognition ? (
        <div>
          <i
            className={`bi bi-mic-fill tw-text-5xl tw-cursor-pointer hover:tw-opacity-80 ${transcript ? 'tw-text-green-500' : 'tw-text-[#0c276c]'} ${listening ? 'tw-animate-pulse !tw-text-red-700' : ''}`}
            onClick={handleMicClick}
          ></i>
          <p className="mt-2 mb-4 mb-sm-5 tw-text-lg sm:tw-text-xl">(Микрофонға қайталап айтыңыз)</p>
        </div>
      ) : (
        <p className="mt-1 mb-4 mb-sm-5 tw-text-lg sm:tw-text-xl">Your browser doesn't support speech recognition</p>
      )}
    </div>
  );
};
