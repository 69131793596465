import React, { FC, useEffect } from 'react';
import { QuizQuestion } from '../../shared/types';

type Props = {
  data: QuizQuestion;
  isSubmitted: boolean;
  currentAnswer: string;
  setCurrentAnswer: (val: string) => void;
  pronounceAudio: (isQuestionPlay?: boolean) => void;
  pauseAudio: () => void;
  isAudioPlaying: boolean;
};

export const CardTemplate3: FC<Props> = ({
  data,
  isSubmitted,
  currentAnswer,
  setCurrentAnswer,
  pronounceAudio,
  pauseAudio,
  isAudioPlaying,
}) => {
  const splittedQuestion = data.qs_dsc?.split('*');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentAnswer(e.target.value);
  };

  useEffect(() => {
    pronounceAudio(true);
  }, [data.id]);

  return (
    <div className="text-center">
      <h4 className="h3">{data.qs_main}</h4>
      <div className="mt-5 tw-flex tw-justify-center tw-items-center tw-flex-wrap gap-2">
        <p className="tw-text-xl sm:tw-text-2xl mb-0">{splittedQuestion?.[0]}</p>
        <input
          disabled={isSubmitted}
          spellCheck={false}
          className="sm:tw-w-[50%] tw-h-[38px] text-center tw-text-xl sm:tw-text-2xl border border-1 border-gray focus-visible:tw-outline-[#0d6efd] bg-white rounded-1 px-2 py-1"
          value={currentAnswer}
          onChange={handleChange}
        />
        <p className="tw-text-xl sm:tw-text-2xl mb-0">{splittedQuestion?.[1]}</p>
        <i
          className="bi bi-headphones tw-text-[#0c276c] tw-text-4xl tw-cursor-pointer hover:tw-opacity-80"
          onClick={() => pronounceAudio(true)}
        ></i>
      </div>

      <div className="btn-group tw-w-full my-4 my-sm-5 tw-flex tw-justify-center tw-flex-col min-[400px]:tw-flex-row gap-3">
        {Object.values(data.paramjs).map((answ) => {
          const isAnswerCorrect = answ.correct === 'true';
          return (
            <div
              className={
                `rounded-1 py-1 px-3 tw-text-xl sm:tw-text-2xl mb-0 ${!isSubmitted ? 'tw-cursor-pointer' : ''} ` +
                (isSubmitted && answ.value === currentAnswer
                  ? isAnswerCorrect
                    ? 'bg-success border-0 text-white'
                    : 'bg-danger text-white border-0'
                  : 'border border-1 border-gray bg-white tw-text-[#0c276c]')
              }
              onClick={() => {
                if (!isSubmitted) {
                  setCurrentAnswer(answ.value);
                }
              }}
            >
              {answ.value}
            </div>
          );
        })}
      </div>
    </div>
  );
};
