import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './chapterDetailsPage.module.scss';
import { Chapter } from '../../shared/types';
import { getId } from '../../shared/utils';

const ChapterDetailsPage = () => {
  const navigate = useNavigate();
  const { categoryId, chapterId } = useParams();
  const [chapter, setChapter] = useState<Chapter>();
  const [videoId, setVideoId] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      fetch('https://saryarqatil.kz/edit/admin/jsoncore.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          command: 'update_list_type',
          tmp_id: categoryId,
          tmp_table: 'tbl_topic',
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          const chapter = data.find((chapter: Chapter) => chapter.id === chapterId);
          setChapter(chapter);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chapter?.link_video) {
      setVideoId(getId(chapter?.link_video as string));
    }
  }, [chapter]);

  return (
    <div className="container tw-py-[20px] sm:tw-py-[36px]">
      <button
        type="button"
        className="btn btn-outline-secondary btn-lg !tw-text-base sm:!tw-text-xl mb-3"
        onClick={() => navigate(-1)}
      >
        <i className="bi bi-chevron-left tw-mr-2"></i>
        Кері оралу
      </button>
      <div className={styles.headCard}>{chapter?.name?.toUpperCase()}</div>
      <div className={videoId ? 'tw-aspect-video' : 'tw-my-[50px] tw-px-[35px]'}>
        {videoId ? (
          <iframe
            width="100%"
            height="100%"
            src={`//www.youtube-nocookie.com/embed/${videoId}?autoplay=0&showinfo=0&controls=0&rel=0`}
            frameBorder={0}
            title={chapter?.name as string}
            allowFullScreen
          ></iframe>
        ) : chapter?.text ? (
          <p className="!tw-font-[400] !tw-text-justify !tw-text-[20px] !tw-italic !tw-mt-[15px]">{chapter?.text}</p>
        ) : (
          <div className="text-center h5 pt-3">деректер жоқ</div>
        )}
      </div>
      <div className="d-flex flex-column align-items-center gap-3 tw-mt-4">
        <button
          type="button"
          className="btn btn-success btn-lg !tw-text-base sm:!tw-text-xl px-5 py-2"
          onClick={() => navigate('quiz')}
        >
          БАСТАУ
        </button>
      </div>
    </div>
  );
};

export default ChapterDetailsPage;
