import React, { useEffect, useState } from 'react';
import { Chapter, QuestionType, QuizQuestion } from '../../shared/types';
import { SingleAnswerCard } from '../../components/singleAnswerCard/singleAnswerCard';
import { MultipleAnswerCard } from '../../components/multipleAnswerCard/multipleAnswerCard';
import { useNavigate, useParams } from 'react-router-dom';
import LessonModal from '../../components/lessonModal/lessonModal';
import styles from './quizPage.module.scss';

export const QuizPage = () => {
  const navigate = useNavigate();
  const { categoryId, chapterId } = useParams();
  const [chapter, setChapter] = useState<Chapter>();
  const [questionsList, setQuestionsList] = useState<QuizQuestion[]>([]);
  const [currentQuestionIdx, setCurrentQuestionIdx] = useState<number>(0);
  const [isQuestionSubmitted, setIsQuestionSubmitted] = useState<boolean>(false);
  const [repeatsCount, setRepeatsCount] = useState<number>(1);
  const [isQuizEnded, setIsQuizEnded] = useState<boolean>(false);
  const [isQuizPassed, setIsQuizPassed] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const fetchData = async () => {
    fetch('https://saryarqatil.kz/edit/admin/jsoncore.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Очень важно для правильной интерпретации сервером
      },
      body: JSON.stringify({
        command: 'update_list_type',
        tmp_table: 'tbl_question',
        tmp_id: chapterId,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data?.length) {
          const dataWithParsedAnswers: any[] = [];
          data.forEach((item: any) =>
            dataWithParsedAnswers.push({ ...item, paramjs: JSON.parse(item.paramjs), isCorrect: false })
          );
          setQuestionsList(dataWithParsedAnswers);
        }
      });
  };

  useEffect(() => {
    (async () => {
      fetch('https://saryarqatil.kz/edit/admin/jsoncore.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          command: 'update_list_type',
          tmp_id: categoryId,
          tmp_table: 'tbl_topic',
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          const chapter = data.find((chapter: Chapter) => chapter.id === chapterId);
          setChapter(chapter);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAnswer = (id: string, isCorrect: boolean) => {
    const newQuestionsList = questionsList.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          isCorrect,
        };
      } else return item;
    });
    setQuestionsList(newQuestionsList);
    setIsQuestionSubmitted(true);
  };

  const setNextQuestion = (index: number) => {
    if (index > questionsList.length - 1) {
      const successCount = questionsList.reduce((acc, cur) => {
        if (cur.isCorrect) {
          return acc + 1;
        } else {
          return acc;
        }
      }, 0);
      const successRate = (successCount * 100) / questionsList.length;
      if (successRate >= 50) {
        setIsQuizPassed(true);
        saveResults(successRate);
        setIsQuizEnded(true);
      } else if (repeatsCount > 3) {
        setIsQuizPassed(false);
        saveResults(successRate);
        setIsQuizEnded(true);
      } else {
        setRepeatsCount((prevState) => prevState + 1);
        setNextQuestion(0);
      }
    } else if (questionsList[index].isCorrect) {
      setNextQuestion(index + 1);
    } else {
      setIsQuestionSubmitted(false);
      setCurrentQuestionIdx(index);
    }
  };

  const handleChangeQuestion = () => {
    setNextQuestion(currentQuestionIdx + 1);
  };

  const restart = () => {
    setRepeatsCount(1);
    setIsQuizEnded(false);
    setIsQuizPassed(false);
    setIsQuestionSubmitted(false);
    setCurrentQuestionIdx(0);
    fetchData();
  };

  const saveResults = (successRate: number) => {
    // @ts-ignore
    set_user_tasks(chapterId, successRate);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="container tw-py-4 sm:tw-py-12 tw-flex tw-justify-center tw-items-center">
      {isQuizEnded ? (
        <div className="card p-5">
          {isQuizPassed ? (
            <div className="text-center fs-2 text-success">
              <i className="bi bi-check-circle tw-mr-3"></i>
              Тест сәтті аяқталды
            </div>
          ) : (
            <div className="text-center fs-2 text-warning">
              <i className="bi bi-x-circle tw-mr-3"></i>
              Тест сәтсіз аяқталды
            </div>
          )}
          <div className="tw-flex tw-justify-center tw-items-center gap-3 mt-3">
            {isQuizPassed ? (
              <button className="btn btn-success px-5 font-bold" onClick={() => navigate(-1)}>
                АЯҚТАУ
              </button>
            ) : (
              <>
                <button className="btn btn-primary px-3 font-bold" onClick={restart}>
                  БАСЫНАН ӨТУ
                </button>
                <button className="btn btn-secondary px-3 font-bold" onClick={() => navigate(-1)}>
                  АЯҚТАУ
                </button>
              </>
            )}
          </div>
        </div>
      ) : !questionsList.length ? (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div>
          <div className="d-flex justify-content-center align-items-center gap-3 mb-4 flex-wrap">
            <button
              className="btn btn-info tw-px-3 font-bold tw-w-full min-[400px]:tw-w-[220px]"
              onClick={() => setShowModal(true)}
            >
              <i className="bi bi-book tw-mr-2"></i>ЕРЕЖЕНІ ҚАЙТАЛАУ
            </button>
            <button
              className="btn btn-warning px-3 font-bold tw-w-full min-[400px]:tw-w-[220px]"
              onClick={() => navigate(-1)}
            >
              <i className="bi bi-x-circle tw-mr-2"></i>АЯҚТАУ
            </button>
            <LessonModal
              show={showModal}
              handleClose={handleCloseModal}
              chapterName={chapter?.name as string}
              videoLink={chapter?.link_video}
              text={chapter?.text}
            />
          </div>
          <div className={styles.quizCard}>
            {[QuestionType.Five, QuestionType.Six, QuestionType.Seven, QuestionType.Eight].includes(
              questionsList[currentQuestionIdx].qs_type
            ) ? (
              <MultipleAnswerCard
                data={questionsList[currentQuestionIdx]}
                handleAnswer={handleAnswer}
                isSubmitted={isQuestionSubmitted}
                handleChangeQuestion={handleChangeQuestion}
              />
            ) : (
              <SingleAnswerCard
                data={questionsList[currentQuestionIdx]}
                handleAnswer={handleAnswer}
                isSubmitted={isQuestionSubmitted}
                handleChangeQuestion={handleChangeQuestion}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
