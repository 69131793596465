import React, { FC, useContext } from 'react';
import styles from './levelCard.module.scss';
import { useNavigate } from 'react-router-dom';
import { UserLevel } from '../../../../shared/types';
import { UserContext } from '../../../../App';
import { useTranslation } from 'react-i18next';

interface Props {
  frontTitle: string;
  frontDesc: string;
  backTitle: string;
  enabledForLevels: UserLevel[];
  level: UserLevel;
  index: number;
}

export const LevelCard: FC<Props> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentUser, changeSelectedLevel } = useContext(UserContext);
  const isCardDisabled =
    !currentUser ||
    (currentUser.user_level !== 'all' && !props.enabledForLevels.includes(currentUser.user_level as UserLevel));
  return (
    <div
      className={`${styles.flipCard} tw-animate-fadeInUp`}
      style={{ animationDuration: `${props.index * 0.4 + 1.25}s` }}
    >
      <div className={styles.flipCardInner}>
        <div className={styles.flipCardFront}>
          <div className={styles.flipCardFrontContent}>
            <div
              className={`tw-px-9 tw-pt-[50px] tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-start ${isCardDisabled ? styles.flipCardFrontContentDisabled : ''}`}
            >
              <h3 className="tw-text-[70px] tw-font-[600]">{props.frontTitle}</h3>
              <p className=" tw-text-center">{t(props.frontDesc)}</p>
            </div>
          </div>
        </div>
        <div className={styles.flipCardBack}>
          <div className={styles.flipCardBackContent}>
            <div
              className={`tw-h-full tw-px-9 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2 ${isCardDisabled ? styles.flipCardBackContentDisabled : ''}`}
            >
              <h5 className="tw-text-[19px] tw-font-600]">{t(props.backTitle)}</h5>
              {!isCardDisabled && (
                <button
                  className={styles.startButton}
                  onClick={() => {
                    if (changeSelectedLevel) {
                      changeSelectedLevel(props.level);
                    }
                    navigate('categories');
                  }}
                >
                  {t('Оқуды бастау')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
